import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'

import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const AerialDroneSurvey = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title="Construction Surveying | Drone Services | Aerial Solutions"
        description="Drone surveys significantly enhance construction project management by providing detailed aerial surveys for precise earthwork calculations, documenting progress and compliance, creating accurate 3D models, and producing visually engaging materials."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            April 15, 2024
          </Typography>
          <Typography variant="h1" color="blue" size={40}>
            Aerial Drone Survey
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Precise earthworks calculation of large areas
              </Typography>
              <Typography variant="body1" size={18}>
                Aerial Drone Survey for earthwork volume calculation: Drones for
                aerial surveys allow for precisely measuring earthwork volumes
                over large construction areas. This technology enables accurate
                and rapid assessments, which is critical for budgeting,
                planning, and resource allocation in construction projects.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-1.png"
                alt="Precise earthworks calculation of large areas"
                title="Precise earthworks calculation of large areas"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Recording scopes, stages, and status of completed construction
                work
              </Typography>
              <Typography variant="body1" size={18}>
                Recording Construction Progress: Drones play a crucial role in
                documenting the stages, scopes, and current status of
                construction projects from an aerial perspective. This
                capability aids in project management and serves as a visual
                record for stakeholders, ensuring transparency and informed
                decision-making.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-2.png"
                alt="Recording scopes, stages, and status of completed construction work"
                title="Recording scopes, stages, and status of completed construction work"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Monitoring and comparison of the correct and as-is positioning
                of engineering communications
              </Typography>
              <Typography variant="body1" size={18}>
                Drones' aerial advantage facilitates monitoring and comparing
                engineering structures' planned versus actual positioning. This
                critical oversight helps identify deviations early, allowing for
                timely corrections to adhere to project specifications.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-3.png"
                alt="Monitoring and comparison of the correct and as-is positioning of engineering communications"
                title="Monitoring and comparison of the correct and as-is positioning of engineering communications"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Monitoring compliance of the performed works with the
                construction organization project
              </Typography>
              <Typography variant="body1" size={18}>
                Compliance with Construction Plans: Drone surveys offer a
                bird's-eye view of ongoing construction works' adherence to the
                project plans. This aerial surveillance ensures that all
                activities comply with the predetermined standards and designs,
                minimizing errors and discrepancies.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-4.png"
                alt="Monitoring compliance of the performed works with the construction organization project"
                title="Monitoring compliance of the performed works with the construction organization project"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Creating 3D terrain models for design purposes
              </Typography>
              <Typography variant="body1" size={18}>
                Creating 3D Terrain Models: Drones' ability to capture detailed
                topographic data is indispensable for creating accurate 3D
                terrain models. These models are crucial for construction
                projects' design and planning phase, providing a realistic
                representation of the terrain for better decision-making.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-5.png"
                alt="Creating 3D terrain models for design purposes"
                title="Creating 3D terrain models for design purposes"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Overviewing photos and 360 panoramas from a drone
              </Typography>
              <Typography variant="body1" size={18}>
                Overviewing Photos and 360-Degree Panoramas in SIGNAX
                INSPECTION. Drones offer an overview of construction sites
                through high-resolution photos and 360-degree panoramas. These
                aerial visuals are essential for comprehensive site analysis,
                progress tracking, and presentation purposes, providing insights
                that ground-level photography cannot achieve.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-6.png"
                alt="Overviewing photos and 360 panoramas from a drone"
                title="Overviewing photos and 360 panoramas from a drone"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              Overviewing photos and 360 panoramas from a drone
            </Typography>
            <Typography variant="body1" size={18}>
              Visualizing Construction Progress and Compliance: Drones provide a
              swift and effective means to visualize construction progress and
              ensure compliance with project timelines and quality standards.
              Regular aerial updates facilitate proactive project management and
              highlight areas needing attention before they become significant
              issues.
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Recording the fact of completed works
              </Typography>
              <Typography variant="body1" size={18}>
                Recording Completed Works: Drones can capture aerial evidence of
                completed works, which is invaluable for project documentation
                and validation. This proof can be used for client reporting,
                quality assurance, and as a historical record of the project's
                completion stages.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-7.png"
                alt="Recording the fact of completed works"
                title="Recording the fact of completed works"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              Marketing materials
            </Typography>
            <Typography variant="body1" size={18}>
              Marketing Materials Production: Aerial imagery and videos captured
              by drones offer a dynamic and engaging way to produce marketing
              materials. Showcasing construction projects from unique vantage
              points can significantly enhance promotional efforts, attracting
              interest and differentiating from competitors.
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Preparing materials for operational meetings and presentations
              </Typography>
              <Typography variant="body1" size={18}>
                Detailed aerial footage and drone images are critical resources
                for operational meetings and presentations utilizing SIGNAX
                INSPECTION. They enable project teams to present progress,
                challenges, and visually engagingly, facilitating better
                communication and stakeholder engagement.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-8.png"
                alt="Preparing materials for operational meetings and presentations"
                title="Preparing materials for operational meetings and presentations"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate('/digital-services/')}
          >
            Other digital services
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default AerialDroneSurvey

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
